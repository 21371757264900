@use '@angular/material' as mat;
@use "sass:math";
@import '~material-design-icons/iconfont/material-icons.css';
@import '~bootstrap/dist/css/bootstrap.min.css';
@import 'assets/ddc-themes';

@include mat.core();

// Define your color palettes
$candy-app-primary: mat.define-palette(mat.$green-palette);
$candy-app-accent: mat.define-palette(mat.$pink-palette, A200, A100, A400);
$candy-app-warn: mat.define-palette(mat.$red-palette);

// Define your theme
$candy-app-theme: mat.define-light-theme((
  color: (
    primary: $candy-app-primary,
    accent: $candy-app-accent,
    warn: $candy-app-warn,
  )
));

@include mat.all-component-themes($candy-app-theme);


/* Application constants */
$ddc-form-fields-vertical-padding: 1em;
$ddc-global-padding: 15px;


/* Common */
html {
  height: 100%;
}

$fontTitle: 'Raleway', sans-serif;
$fontContent: 'Sarabun', sans-serif;

body {
  margin: 0;
  height: 100%;
  font-family: $fontContent !important;
}

p, td, th, mat-card-content, button, mat-checkbox {
  font-family: $fontContent !important;
}

.sarabun {
  font-family: 'Sarabun', sans-serif;
}

.raleway {
  font-family: 'Raleway', sans-serif;
}


.title {
  font-family: $fontTitle;
}

/* Component overriding */
input[type=number] {
  -moz-appearance: textfield;
}

mat-card {
  padding: $ddc-global-padding;
}

mat-placeholder {
  pointer-events: auto;
}

.mat-form-field-appearance-legacy .mat-form-field-infix {
  padding: $ddc-form-fields-vertical-padding 0;
}

.ngx-timepicker {
  border-bottom: none !important;
}

.ngx-timepicker-control > input {
  background: transparent !important;
}


/* Templating and position overriding */
.ddc-no-padding {
  padding: 0;
}

.ddc-no-padding-left {
  padding-left: 0;
}

.ddc-padding {
  padding: $ddc-global-padding;
}

.ddc-padding-vertical {
  padding-top: math.div($ddc-global-padding, 2);
  padding-bottom: math.div($ddc-global-padding, 2);
}

.ddc-margin-auto {
  margin: auto
}

.title {
  font-family: Sarabun, 'Helvetica Neue', sans-serif;
}

.ddc-anti-margin-top {
  margin-top: -15px;
}

.ddc-offset-auto {
  margin-left: auto;
}


.ddc-textfield-btn-div {
  display: block;
  margin: auto 0 auto auto;
  /*padding-left: 0 !important;
  padding-right: 0 !important;*/
}

div.ddc-table-noscroll-div-container {
  margin-bottom: 15px;
  border-radius: 2px;
}

div.ddc-table-div-container {
  @extend .ddc-table-noscroll-div-container;
  height: 300px;
  overflow: auto;
}

div.ddc-table-div-container-noscroll {
  overflow-x: auto;
}

div.ddc-short-table-div-container {
  @extend .ddc-table-noscroll-div-container;
  overflow-x: auto;
}

/*mat-mdc-tab-header {
  margin-bottom: 30px;
  margin-top: 15px;
}*/

/*.mat-mdc-tab-label-container {
  justify-content: left !important;
  min-width: max-content !important;
  width: max-content !important;
  padding: 0 10px !important;
  font-weight: normal;
}*/
/*
.mat-mdc-tab-label span:first-child {
  font-variant: small-caps;
}

.mat-mdc-tab-label span:nth-child(2) {
  margin-left: 5px;
  padding-top: 4px;
}*/

.mat-drawer-inner-container {
  overflow: hidden !important;
  height: auto;
}

.mat-expansion-panel:not([class*="mat-elevation-z"]) {
  box-shadow: 0 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
}

.mat-figure {
  display: flow-root !important;
  margin: 10px !important;
}

.row.ddc-card-with-title-container {
  padding-top: 24px;
  padding-bottom: 12px;
}

[mat-table] {
  width: 100%;
}

.card-remarque {
  display: block;
}

mat-card.ddc-card-with-title {
  @extend .mat-elevation-z2;
  margin-top: 24px;
  margin-bottom: 12px;
  border: 1px solid #919492;
  font-family: $fontTitle;

  & > mat-card-title {
    border-radius: 2px;
    margin-top: -32px !important;
    background-color: #919492;
    width: fit-content;
    padding: 0 10px;
    font-variant: petite-caps;
    margin-bottom: 16px;
    border: solid 1px darken(#919492, 5%);
    color: white;
    font-size: 1.15em;
    box-shadow: 0 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  }
}

mat-card.ddc-subcard-with-title-dialog {
  @extend .ddc-card-with-title;

  & > mat-card-title {
    background-color: white;
    color: #919492;
  }
}

// remove the floating rendering of survey' checkboxes
label.mat-radio-label {
  margin-bottom: 0 !important;
}

// make space between radio options
mat-radio-button {
  margin-right: 10px;

  &:first-of-type {
    margin-left: 10px;
  }
}

/* Colors and other */
.mat-mdc-snack-bar-container {
  &.success-snackbar {
    --mdc-snackbar-container-color: #65bc99;
  }

  &.warning-snackbar {
    --mdc-snackbar-container-color: #F3C13A;
  }

  &.info-snackbar {
    --mdc-snackbar-container-color: #5D8CAE;
  }

  &.danger-snackbar {
    --mdc-snackbar-container-color: #DC3023;
  }
}

.ddc-required {
  color: red;
  margin-left: 5px;
}

.ddc-right {
  justify-content: right;
  /*margin: 16px 0 0 0;*/
  padding: 15px;

  & > .col-auto {
    padding-right: 0;
  }
}

.ddc-left {
  justify-content: left;
  /*margin: 16px 0 0 0;*/
  padding: 15px;

  & > .col-auto {
    padding-left: 0;
  }
}

.ddc-button-right {
  margin-right: 0 !important;
  display: flex !important;
}

.ddc-margin-bottom {
  margin-bottom: 10px!important;
}

.ddc-button-top {
  margin-right: 20px !important;
  margin-top: -18px !important;
  margin-bottom: 10px !important;
}

.ddc-button-toggle-disp-hist {
  margin-bottom: 25px !important;
}

.ddc-recherche-multi {
  display: flex !important;
  justify-content: flex-end !important;
}

.ddc-recherche-multi-button{
  margin: 0 15px !important;
}

.ddc-button-left {
  margin-left: 0 !important;
}

.ddc-warning-message {
  color: #ff9100;
}

.ddc-warning-message > span:first-child {
  display: inline-flex;
  vertical-align: bottom;
  margin-right: 10px;
}

.ddc-error-message {
  color: #DC3023;
}

.ddc-error-message > span:first-child {
  display: inline-flex;
  vertical-align: bottom;
  margin-right: 10px;
}

@each $theme-name,
$theme-background-image,
$theme-primary-color,
$theme-secondary-color,
$theme-tertiary-color,
$theme-quaternary-color,
$theme-primary-muted-color,
$theme-secondary-muted-color,
$theme-tertiary-muted-color,
$theme-warn-color in $colors {
  mat-toolbar.ddc-header-container {
    display: flex;
    height: 150px !important;
  }

  mat-form-field {
    margin-bottom: 5px;
  }

  .mat-form-field-ripple {
    background-color: $theme-primary-color;
  }

  .ddc-theme-#{$theme-name} .mat-slide-toggle-thumb {
    background-color: $theme-primary-color !important;
  }

  .ddc-theme-#{$theme-name} .mat-slide-toggle.mat-checked .mat-slide-toggle-bar {
    background-color: $theme-primary-muted-color !important;
  }

  .ddc-theme-#{$theme-name} mat-expansion-panel-header.ddc-disable-ripple:hover {
    background-color: $theme-secondary-color !important;
  }

  .material-icons {
    width: 24px !important;
    margin-left: 5px;
    margin-right: 5px;
    margin-top: 5px;
  }

  .ddc-theme-#{$theme-name} .material-icons, .ddc-theme-#{$theme-name} span {
    &.ddc-icon {
      color: /*$theme-primary-color*/
        #848785;
    }

    &.ddc-icon-warm {
      color: #DC3023; //TODO: replace by the theme's warn color
    }

    &.ddc-icon-success {
      color: #65bc99; //TODO: define a success color and replace by it
    }

    &.ddc-icon-accent {
      color: #F3C13A; //TODO: define a accent color and replace by it
    }

    &.ddc-icon-info {
      color: #83aed6;
    }
  }

  .ddc-theme-#{$theme-name} mat-icon.ddc-primary {
    color: $theme-primary-color;
  }

  .ddc-theme-#{$theme-name} .ddc-summary mat-card {
    border: 1px solid $theme-primary-color;
  }
  // Modal style
  .ddc-theme-#{$theme-name} .ddc-modal-dialog-title {
    color: white !important;
    background-color: $theme-primary-color;
  }

  // Modal Buttons
  button.ddc-button-primary {
    background-color: $theme-primary-color;
    color: white !important;
  }

  button.ddc-inversed-button-primary {
    background-color: white;
    color: #003a5d !important;
    border-color: #003a5d !important;
  }

  button.ddc-button-secondary {
    background-color: $theme-secondary-color !important;
    color: white !important;
  }

  button {
    display: block;
    margin: auto;
    outline: 0;
  }

  .ddc-theme-#{$theme-name} table {
    & > thead .mat-mdc-header-cell {
      background-color: $theme-secondary-color !important;
      color: white;
     // z-index: 0!important;
    }

    & > tbody > tr.mat-mdc-row {
      cursor: pointer;
      outline: 0;

      &:hover > td:first-child {
        border-left: 10px solid $theme-secondary-color;
      }

      & > td:first-child {
        border-left: 10px solid transparent;
      }
    }
  }

  .ddc-theme-#{$theme-name} .read-only-mat-table tbody {
    & > tr {
      cursor: default !important;

      &:hover > td {
        border-left: 0 !important;
      }
    }
  }

  td.ddc-theme-#{$theme-name}-column-marker {
    border-left: 15px solid $theme-primary-color !important;
  }

  .ddc-theme-#{$theme-name} .ddc-page-content h1 {
    color: $theme-primary-color;
  }

  // All Buttons
  .ddc-theme-#{$theme-name} button.ddc-button-primary {
    background-color: $theme-primary-color;
    color: white !important;
  }

  .ddc-theme-#{$theme-name} button.ddc-button-secondary {
    background-color: $theme-secondary-color !important;
    color: white !important;
  }

  .ddc-theme-#{$theme-name} button.mat-warn {
    background-color: $theme-warn-color !important;
  }

  .ddc-theme-#{$theme-name} button {
    display: block;
    margin: auto;
    outline: 0;
  }

/*  .ddc-theme-#{$theme-name} .mat-mdc-tab-group .mat-mdc-ink-bar,
  .ddc-theme-#{$theme-name} .mat-mdc-form-field.mat-mdc-focused .mat-form-field-ripple {
    background-color: $theme-primary-color !important;
  }*/

  /*.ddc-theme-#{$theme-name} .mat-tab-label .mdc-tab--active {
    color: $theme-primary-color;
    font-weight: bold;
    opacity: 1;
  }*/

  .ddc-theme-#{$theme-name} .mdc-tab--active span:first-child {
    font-variant: small-caps;
    color: $theme-primary-color;
    font-weight: bold;
  }

  .mat-mdc-tab-group,.mat-mdc-tab-nav-bar {
    --mdc-tab-indicator-active-indicator-color: $theme-primary-color!important;
    --mat-tab-header-active-hover-label-text-color: $theme-primary-color!important;
    --mat-tab-header-active-hover-indicator-color: $theme-primary-color!important;
    --mat-tab-header-active-focus-indicator-color: $theme-primary-color!important;
    --mat-tab-header-inactive-ripple-color: $theme-primary-color!important;
    --mat-tab-header-active-focus-label-text-color: $theme-primary-color!important;
  }

  .mat-mdc-tab .mdc-tab-indicator__content--underline {
    border-top-width: 4px!important;
  }

  .mdc-tab {
    padding-right: 7px!important;
    padding-left: 7px!important;
    min-width: inherit!important;
   }

  .ddc-theme-#{$theme-name} .mat-input-element {
    caret-color: $theme-primary-color;
  }

  .ddc-theme-#{$theme-name} .mat-calendar-body-cell-content.mat-calendar-body-selected {
    background-color: $theme-primary-color !important;
    color: white !important;
  }

  .ddc-theme-#{$theme-name} .mat-form-field-label,
  .ddc-theme-#{$theme-name} mat-form-field.mat-form-field.mat-focused .mat-form-field-label {
    color: $theme-primary-color !important;
    font-weight: bold;
  }

  .mdc-text-field--filled:not(.mdc-text-field--disabled) {
    background-color: white !important;
  }

  .mat-mdc-form-field-focus-overlay {
    background-color: transparent !important;
  }

  /* Changer la couleur de la bordure et du texte de l'input en focus */
  .mat-mdc-form-field.mat-focused .mdc-floating-label {
    color: black !important;
  }

  .ddc-theme-#{$theme-name} mat-card-title {
    color: $theme-primary-color;
  }

  .ddc-theme-#{$theme-name} mat-panel-title {
    color: $theme-secondary-color;
  }

  .ddc-theme-#{$theme-name} mat-expansion-panel-header.ddc-disable-ripple:hover mat-panel-title {
    color: white;
  }

  .ddc-theme-#{$theme-name} .dcc-section-subtitle {
    color: $theme-secondary-color;
    margin-top: 15px;
    margin-bottom: 10px;
  }

  .ddc-theme-#{$theme-name} {
    .mat-radio-button.mat-accent .mat-radio-inner-circle {
      background-color: $theme-primary-color;
    }

    .mat-radio-button.mat-accent.mat-radio-checked .mat-radio-outer-circle {
      border-color: $theme-primary-color;
    }

    .mat-checkbox-indeterminate.mat-accent .mat-checkbox-background,
    .mat-checkbox-checked.mat-accent .mat-checkbox-background {
      background-color: $theme-secondary-color;
    }

    .mat-checkbox-checked:not(.mat-checkbox-disabled).mat-accent .mat-ripple-element,
    .mat-checkbox:active:not(.mat-checkbox-disabled).mat-accent .mat-ripple-element {
      background: $theme-secondary-color !important;
    }

    .sv_p_container {
      h4 {
        font-size: 2em;
        font-weight: bold;
        color: $theme-primary-color;
      }

      * {
        h4 {
          font-size: 1.5em;
          color: $theme-secondary-color;
          display: list-item;
          list-style-type: disc;
          list-style-position: inside;
        }
      }
    }

    .sv_q_title {
      @extend .dcc-section-subtitle;
    }

    .sv_p_title {
      @extend .sv_q_title;
      padding-left: 0;
    }

    .sv_q_radiogroup, .sv_q_description, .sv_p_description {
      padding-left: 16px;
    }

    .sv_p_description {
      border-left: $theme-primary-color 4px solid;
      font-style: italic;
      margin-bottom: 10px;
    }

    .sv_q_description {
      border-left: $theme-primary-color 2px solid;
      font-style: italic;
      margin-bottom: 10px;
      margin-left: 16px;
    }

    .sv_header {
      h3 {
        color: $theme-primary-color;
        display: none;
      }

      h5 {
        color: $theme-secondary-color;
      }
    }

    .sv_q textarea {
      margin-top: 10px;
      width: 100%;
      padding: 5px;
      border: 1px solid $theme-primary-color;
      border-radius: 5px;
    }

    .sv_nav {
      text-align: right;
      margin-top: 15px;

      input {
        box-sizing: border-box;
        position: relative;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
        cursor: pointer;
        outline: 0;
        border: none;
        -webkit-tap-highlight-color: transparent;
        display: inline-block;
        white-space: nowrap;
        text-decoration: none;
        vertical-align: baseline;
        text-align: center;
        margin: 0;
        min-width: 64px;
        line-height: 36px;
        padding: 2px 20px;
        border-radius: 4px;
        overflow: visible;
        color: white;
        background-color: $theme-primary-color;
        font-weight: bold;
      }
    }

    .sd-action-bar{
      justify-content: center !important;
      margin-right: auto !important;
      min-width: 100px !important;
    }

    .sd-footer{
      justify-content: end !important;
      margin-right: 0 !important;
    }

    .sd-action{
      margin-left: auto !important;
    }

    .ddc-surveyjs-container table {
      width: 100%;
      border: 1px solid $theme-secondary-color;

      & > thead {
        background-color: $theme-secondary-color;
        color: white;

        & th {
          height: 56px;
          padding-left: 5px;
          text-align: center;

          &:first-child {
            padding-left: 10px;
          }

          & span{
            color: white;
          }
        }

        & > tr > td{
          background-color: $theme-secondary-color;
        }
      }

      & > tbody > tr {
        cursor: pointer;
        outline: 0;

        &:hover > td:first-child {
          border-left: 10px solid $theme-secondary-color;
        }

        & > td {
          text-align: center;
          color: white;
        }

        & > td:first-child {
          border-left: 10px solid transparent;
          text-align: left;
          color: black;
        }

        & > td textarea {
          margin-top: 0;
          height: 80px;
        }

      }

    }

    div.sv_row ~ div.sv_row {
      margin-top: 15px;
    }

    table.sv_q_mt {
      width: 50%;

      & > tbody td > .sv_q_text_root {
        width: 100%;
        border: 1px solid $theme-secondary-color;
      }
    }

    .ddc-radio-buttons-group {
      display: flex;
      flex-direction: column;
      margin: 15px 0;
    }

    .ddc-radio-button {
      margin: 10px;
    }

    .ddc-appli-description {
      border-left: $theme-primary-color 2px solid;
      font-style: italic;
      margin-bottom: 16px;
      margin-left: 16px;
      padding-left: 16px;
    }

    .ddc-appli-description-audace {
      color: $ddc-color-audace-primary;
      font-style: italic;
      font-size: 14pt;
      margin-bottom: 16px;
      margin-left: 16px;
      padding-left: 16px;
    }

    .ddc-appli-quote {
      font-family: 'Caveat Brush';
      padding: 7px;
      margin-bottom: 7px;
      margin-left: 16px;
      font-size: 14pt;
    }

    .ddc-adherent-info-title {
      margin: 10px 0 10px 0;
      color: $theme-primary-color !important;
    }

    .ddc-modules-response {
      font-style: italic;
      margin-bottom: 16px;
      margin-left: 16px;
    }

    .ddc-modules-title {
      margin-bottom: 16px;
      font-weight: bold;
    }

    .ddc-external-link {
      color: $theme-secondary-color;
      cursor: pointer;
    }

    .ddc-read-only-data-container {
      & .ddc-label {
        padding-right: 5px;
        font-weight: 300;
      }

      & .ddc-value {
        color: $theme-primary-color;
      }

      & .ddc-value-with-icon {
        color: $theme-primary-color;

        & > span {
          vertical-align: top;
        }
      }
    }

    .ddc-mat-label-outside-form-field {
      color: $theme-primary-color;
      font-weight: bold;
      font-size: 0.75em;
    }


    .ddc-avis {
      // for the semi-colon
      font-size: 14px;
      font-weight: 500;

      & .ddc-read-only-data-container {
        margin-bottom: 15px;

        & > span {
          font-size: 14px;
          font-weight: 500;

          &.ddc-label {
            color: $theme-primary-color;
          }

          &.ddc-value {
            color: black;
          }
        }
      }
    }


    .ddc-autorisation {
      @extend .ddc-avis;
    }

    .mat-mdc-tab-header {
      margin-bottom: 0 !important;
      margin-top: 0 !important;
      border-bottom-width: 0 !important;
      font-variant: small-caps;
    }

    .mat-mdc-tab-body-wrapper {
      border: 1px solid $theme-primary-color;
      box-shadow: 0 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
      border-bottom-left-radius: 4px;
      border-bottom-right-radius: 4px;
    }
    mat-card.ddc-subcard-with-title {
      @extend .mat-elevation-z0;
      margin-top: 20px;
      margin-bottom: 0;
      border-top: 2px solid $theme-primary-color;
      background-color: /*lighten($theme-primary-color, 40%)*/
        $theme-tertiary-muted-color;

      & > mat-card-title {
        border-radius: 2px;
        margin-top: -32px !important;
        background-color: $theme-primary-color;
        width: fit-content;
        padding: 0 10px;
        font-variant: petite-caps;
        margin-bottom: 16px;
        border: solid 1px /*darken($theme-primary-color, 5%)*/
        $theme-tertiary-color;
        color: white;
        font-size: 1.10em;
        box-shadow: 0 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
      }
    }

    mat-progress-spinner {
      &.loading-spinner {
        height: 150px !important;
        width: 150px !important;
        position: fixed;
        left: calc(50% - 75px);
        top: calc(50% - 75px);
        z-index: 3500;
      }

      &.loading-spinner circle {
        stroke: $theme-primary-color;
      }

      &.loading-spinner > svg {
        height: 150px !important;
        width: 150px !important;
      }
    }

    /**
     * Panel de droite pour infos complémentaires
     */

    .mat-drawer {
      background-color: /*lighten($theme-primary-color, 40%)*/
        $theme-tertiary-muted-color;
    }

    /*.mat-drawer-accordion {

      display: block;
      margin-bottom: 15px;

      & > .mat-expansion-panel {
        background-color: !*lighten($theme-primary-color, 30%)*!
          $theme-secondary-muted-color;
        padding: 15px;

        & > .mat-expansion-panel-header {
          //margin: -15px -15px 0 -15px;
          margin: -15px;
          background-color: $theme-secondary-color;
          color: white;
          font-size: 1em;
          padding: 15px;

          & mat-panel-title {
            color: white !important;

            & .ddc-subtitle {
              font-size: 0.75em;
              font-style: italic;
            }
          }
        }

        & .mat-expansion-panel-body {
          padding: 30px 0 0 0;
        }
      }

      mat-card {
        background-color: !*lighten($theme-primary-color, 30%)*!
          $theme-secondary-muted-color;
        margin: 0 -15px -15px;
        padding-top: 0;

        & > mat-card-content {
          margin: 0 -15px;
          display: grid;
          text-align: justify-all;
        }

        & > mat-card-footer {
          margin: 0px -15px -15px -15px;
          border-top: solid 2px !*darken($theme-primary-color, 5%)*!
          $theme-tertiary-color;
          padding: 8px 15px;
        }
      }
    }*/

    .mat-mdc-card-content {
        margin: 0 -15px;
        //display: grid;
        //text-align: justify-all;
      }

    .ddc-msg-block {
      min-width: 40%;
      max-width: 70%;
      margin: 8px 15px;
      padding: 8px;
      border-radius: 5px;
      text-align: justify-all;
    }

    .ddc-msg-block-sent {
      @extend .ddc-msg-block;
      background-color: /*lighten($theme-secondary-color, 20%)*/
        $theme-quaternary-color;
      float: right;
    }

    .ddc-msg-block-received {
      @extend .ddc-msg-block;
      background-color: /*lighten($theme-secondary-color, 30%)*/
        $theme-primary-muted-color;
      float: left;
    }

    .ddc-msg-date {
      font-size: 0.6em;
      margin: 4px 0 0 0;
      float: right;
      font-style: italic;
    }

    .ddc-msg-text {
      margin-bottom: 2px;
      white-space: pre-line;
    }

    .ddc-msg-owner {
      color: #555b90;
      font-size: 0.8em;
      font-weight: bold;
      margin-bottom: 2px;
    }

    .mat-button-toggle {
      background-color: /*lighten($theme-secondary-color, 20%)*/
        $theme-quaternary-color;
      color: white;
    }

    .mat-button-toggle-checked {
      background-color: $theme-secondary-color;
      color: white;
    }

    .ddc-footer {
      position: absolute;
      left: 0;
      width: 100%;
      background-color: $theme-primary-color;
      border-radius: 0;
      z-index: 1000;
      margin-top: 1px;
    }

    @media screen and (max-width: 770px) {
      .ddc-footer {
        position: relative;
      }
    }

    .ddc-footer-block {
      padding-top: 10px;
      padding-bottom: 5px;
      margin: 0 !important;
    }

    .ddc-footer-text {
      color: white;
      padding-left: 30px;
    }

    .ddc-footer a {
      text-decoration: underline white !important;
      float: right;
      color: white;
    }

    .ddc-progession-synthesis-recherche-camp {

      & > div {

        &.ddc-icon {
          color: #848785;
        }

        &.ddc-icon-warm {
          color: #DC3023;
        }

        &.ddc-icon-accent {
          color: #F3C13A
        }

        &.ddc-icon-success {
          color: #65bc99;
        }

        &.ddc-icon-info {
          color: #83aed6;
        }
      }

      & > .mat-icon {
        font-size: 19px;
      }
    }

    .ddc-progession-synthesis {
      margin-left: 15px;
      display: inline-flex;

      & > div {
        padding: 0 7px 0 7px;

        &.ddc-icon {
          color: #848785;
        }

        &.ddc-icon-warm {
          color: #DC3023;
        }

        &.ddc-icon-accent {
          color: #F3C13A
        }

        &.ddc-icon-success {
          color: #65bc99;
        }

        &.ddc-icon-info {
          color: #83aed6;
        }

        &:last-child {
          border: 0 !important;
        }
      }

      & > .mat-icon {
        font-size: 19px;
        width: 19px;
        height: 19px;
        margin-left: 10px;
      }
    }

    .ddc-type-camp {
        font-size: 17px;
        padding: 2px 10px;
        background-color: $theme-primary-color;
        border-radius: 4px;
        color: white;
        margin-right: 7px;
        font-weight: bold;
    }


    .ddc-type-projet-annee {
      font-size: 17px;
      padding: 2px 10px;
      background-color: $theme-primary-color;
      border-radius: 4px;
      color: white;
      margin-right: 7px;
      font-weight: bold;
    }

    .ddc-title-onglet {
      padding-left: 18px;
      font-weight: bold;
      & > span {
        font-size: 20px;
        color: $theme-primary-color;
      }
    }

  }

  table.mat-calendar-table td.mat-calendar-body-cell > .mat-calendar-body-cell-content.mat-calendar-body-selected {
    background-color: darken(aliceblue, 7%) !important;
    color: black;
  }

  td button, .ddc-btn-mini {
    padding: 0 6px !important;
    width: auto;
    min-width: unset !important;
  }

  .ddc-textfield-btn-div > button {
    @extend .ddc-btn-mini /*, .ddc-button-left*/
  }

  .ddc-piece-jointe-row {
    margin-bottom: 16px;
  }

  .ddc-piece-jointe-buttons-container {
    display: inline-block;
  }

  .ddc-piece-jointe-buttons-container > button {
    display: inline-block;
  }

  .ddc-piece-jointe-buttons-container > button:first-child {
    margin-right: 10px;
    background-color: $theme-primary-color;
  }

  .ddc-theme-#{$theme-name} .ddc-compteur-projets {
    background-color: $theme-primary-color;
    color: white;
    width: 40px;
    height: 40px;
    text-align: center;
    padding-top: 10px;
    border-radius: 20px;
    float: right;
  }

  .ddc-theme-#{$theme-name} .ddc-details-container {
    background-color: $theme-tertiary-muted-color;
    margin: 5px 0 5px 0;
    padding: 7px;
    border-radius: 7px;
  }

  .ddc-icon-beside-text {
    vertical-align: bottom;
    margin-right: 7px;
  }

  .ddc-projet-annule {
    background-color: #CCCCCC !important;
  }

  .ddc-projet-archive {
    background-color: #A8A8A8 !important;
  }

  .ddc-with-break-lines {
    white-space: pre-wrap;
  }

  .ddc-tooltip-icon {
    text-align: center;
    /*
    background-color: #003a5d;
    */
    font-size: 15px;
    min-width: 200px;
  }

  .footer-bottom-page {
    position: relative;
    min-height: calc(100vh - 211px);
  }

  .ddc-title {
    max-width: 55%;
  }

}
